<template>
  <van-form ref="form">
    <van-cell-group title="一志愿报考信息">
      <radio-cell-group clear v-model="universityItem" title="学校" placeholder="请选择学校" @change="onChange1">
        <radio-cell v-for="(item, index) in universityList" :key="index" :name="item" :label="item.UniversityName"></radio-cell>
      </radio-cell-group>
      <radio-cell-group clear v-model="collegeItem" title="学院" placeholder="请选择学院" @change="onChange2">
        <radio-cell v-for="(item, index) in collegeList" :key="index" :name="item" :label="item.CollegeCode + item.CollegeName"></radio-cell>
      </radio-cell-group>
      <radio-cell-group clear v-model="majorItem" title="专业" placeholder="请选择专业" @change="onChange3">
        <radio-cell v-for="(item, index) in majorList" :key="index" :name="item" :label="`${item.MajorCode}${item.MajorName}`">
          <template>
            {{ `${item.MajorCode}${item.MajorName}/${item.CollegeCode}${item.CollegeName}` }}
          </template>
        </radio-cell>
      </radio-cell-group>
      <radio-cell-group clear v-model="formData.majorLearningWay" title="学习方式" placeholder="请选择学习方式" @change="onChange4">
        <radio-cell v-for="(item, index) in learningWays" :key="index" :name="item" :label="item"></radio-cell>
      </radio-cell-group>
    </van-cell-group>
    <van-cell-group title="初试成绩">
      <van-field label="政治" placeholder="请填写政治分数" type="number" v-model="formData.politicalScore" :rules="[{ required: true }]" />
      <van-field label="外语" placeholder="请填写外语分数" type="number" v-model="formData.englishScore" :rules="[{ required: true }]" />
      <van-field label="专业一" placeholder="请填写专业一分数" type="number" v-model="formData.majorScore1" :rules="[{ required: true }]" />
      <van-field label="专业二" placeholder="请填写专业二分数" type="number" v-model="formData.majorScore2" :rules="[{ required: true }]" />
      <van-field label="总分" type="number" readonly :value="totalScore" />
    </van-cell-group>
  </van-form>
</template>

<script>
import RadioCell from "@/components/MT/RadioCell";
import RadioCellGroup from "@/components/MT/RadioCellGroup";
import Config from "@/api/service";
export default {
  name: "SmartAdjustForm",
  components: {
    RadioCell,
    RadioCellGroup,
  },
  props: {
    formData: {
      type: Object,
      default: {
        universityCode: null,
        collegeCode: null,
        majorCode: null,
        majorLearningWay: null,
        politicalScore: 0,
        englishScore: 0,
        majorScore1: 0,
        majorScore2: 0,
      },
    },
  },
  data() {
    return {
      learningWays: ['全日制', '非全日制'],
      universityList: [],
      collegeList: [],
      majorList: [],
    };
  },
  computed: {
    universityItem: {
      get: function () {
        return this.universityList.find(item => item.UniversityCode == this.formData.universityCode)
      },
      set: function (item) {
        this.formData.universityCode = item.UniversityCode;
      }
    },
    collegeItem: {
      get: function () {
        return this.collegeList.find(item => item.UniversityCode == this.formData.universityCode && item.CollegeCode == this.formData.collegeCode)
      },
      set: function (item) {
        this.formData.collegeCode = item.CollegeCode;
      }
    },
    majorItem: {
      get: function () {
        return this.majorList.find(item => item.UniversityCode == this.formData.universityCode && item.CollegeCode == this.formData.collegeCode && item.MajorCode == this.formData.majorCode)
      },
      set: function (item) {
        this.formData.collegeCode = item.CollegeCode;
        this.formData.majorCode = item.MajorCode;
      }
    },
    universityText() {
      if (this.universityItem) {
        return this.universityItem.UniversityName;
      } else {
        return this.formData.universityCode;
      }
    },
    collegeText() {
      if (this.collegeItem) {
        return this.collegeItem.CollegeCode + this.collegeItem.CollegeName;
      } else {
        return this.formData.collegeCode;
      }
    },
    majorText() {
      if (this.majorItem) {
        return this.majorItem.MajorCode + this.majorItem.MajorName;
      } else {
        return this.formData.majorCode;
      }
    },
    majorLearningWayText() {
      return this.formData.majorLearningWay;
    },
    totalScore() {
      var total = Number(this.formData.politicalScore) + Number(this.formData.englishScore) + Number(this.formData.majorScore1) + Number(this.formData.majorScore2);
      if (isNaN(total)) {
        return 0;
      } else {
        return total;
      }
    }
  },
  methods: {
    //自动加载数据
    autoLoadData() {
      this.getAllCollegeList();
      this.getAllMajorList();
    },
    //重置数据
    resetData() {
      this.formData.universityCode = null;
      this.formData.collegeCode = null;
      this.formData.majorCode = null;
      this.formData.majorLearningWay = '全日制';
      this.formData.politicalScore = 0;
      this.formData.englishScore = 0;
      this.formData.majorScore1 = 0;
      this.formData.majorScore2 = 0;
      this.universityList = [];
      this.collegeList = [];
      this.majorList = [];
      this.getAllUniversityList();
    },
    //验证数据
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate().then((res) => {
          resolve(res);
        }).catch((error) => {
          reject(error);
        })
      });
    },
    //获取所有大学列表
    getAllUniversityList() {
      setTimeout(() => {
        var model = {};
        this.$axios
          .post(Config.smartAdjust.form.schoolList, model)
          .then((res) => {
            this.universityList = res.data.Data.UniversityInfos;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    //获取所有学院列表
    getAllCollegeList() {
      setTimeout(() => {
        var model = {
          UniversityCode: this.formData.universityCode
        };
        this.$axios
          .post(Config.smartAdjust.form.collegeList, model)
          .then((res) => {
            this.collegeList = res.data.Data.CollegeInfos;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    //获取所有专业列表
    getAllMajorList() {
      setTimeout(() => {
        var model = {
          UniversityCode: this.formData.universityCode,
          CollegeCode: this.formData.collegeCode
        };
        this.$axios
          .post(Config.smartAdjust.form.majorList, model)
          .then((res) => {
            this.majorList = res.data.Data.MajorInfos;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    //选择大学改变
    onChange1() {
      this.formData.collegeCode = null;
      this.formData.majorCode = null;
      this.collegeList = [];
      this.majorList = [];
      this.getAllCollegeList();
      this.getAllMajorList();
    },
    //选择学院改变
    onChange2() {
      this.formData.majorCode = null;
      this.majorList = [];
      this.getAllMajorList();
    },
    //选择专业改变
    onChange3() {

    },
    //选择专业学习方式改变
    onChange4() {

    },
  },
  mounted() {
    this.resetData();
  }
};
</script>

<style scoped lang="less">
.popup {
  height: 80%;
}

.popup-content {
  margin: 0px 10px 10px 10px;
}

::v-deep .van-cell-group__title {
  color: black;
  font-weight: bold;
}
</style>
